<template>
    <div class="m-auto" style="max-width: 960px;">
        <div v-if="supplier.id">
            <el-card header="用户信息" shadow="never" class="m-3">
                <el-row :gutter="8">
                    <el-col :span="24" class="property-group">
                        <span class="property-name">类型</span>
                        <span class="property-value">{{supplier.registeredUser.typeName}}</span>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col v-if="supplier.registeredUser.type==2" :span="12" class="property-group">
                        <span class="property-name">姓名</span>
                        <span class="property-value">{{supplier.registeredUser.name}}</span>
                    </el-col>
                    <el-col v-if="supplier.registeredUser.type==3" :span="12" class="property-group">
                        <span class="property-name">企业名称</span>
                        <span class="property-value">{{supplier.registeredUser.name}}</span>
                    </el-col>
                    <el-col :span="12" class="property-group">
                        <span class="property-name">电话</span>
                        <span class="property-value">{{supplier.registeredUser.phone}}</span>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12" class="property-group">
                        <span class="property-name">水发公司</span>
                        <span class="property-value">{{supplier.registeredUser.companyName}}</span>
                    </el-col>
                    <el-col :span="12" class="property-group">
                        <span class="property-name">注册时间</span>
                        <span class="property-value">{{$moment.sdt(supplier.registeredUser.createTime)}}</span>
                    </el-col>
                </el-row>
            </el-card>

            <el-card header="供应商信息" shadow="never" class="m-3">
                <el-row :gutter="8">
                    <el-col :span="12" class="property-group">
                        <span class="property-name">法人</span>
                        <span class="property-value">{{supplier.legalPersonName}}</span>
                    </el-col>
                    <el-col :span="12" class="property-group">
                        <span class="property-name">法人电话</span>
                        <span class="property-value">{{supplier.legalPersonPhone}}</span>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12" class="property-group">
                        <span class="property-name">联系人</span>
                        <span class="property-value">{{supplier.contactName}}</span>
                    </el-col>
                    <el-col :span="12" class="property-group">
                        <span class="property-name">联系电话</span>
                        <span class="property-value">{{supplier.contactPhone}}</span>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12" class="property-group">
                        <span class="property-name">主营品牌</span>
                        <span class="property-value">{{supplier.mainBrand}}</span>
                    </el-col>
                    <el-col :span="12" class="property-group">
                        <span class="property-name">经营区划</span>
                        <span class="property-value">{{supplier.regionName}}</span>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="24" class="property-group">
                        <span class="property-name">地址</span>
                        <span class="property-value">{{supplier.address}}</span>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12" class="property-group">
                        <span class="property-name">开户行</span>
                        <span class="property-value">{{supplier.openingBank}}</span>
                    </el-col>
                    <el-col :span="12" class="property-group">
                        <span class="property-name">开户名</span>
                        <span class="property-value">{{supplier.accountName}}</span>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12" class="property-group">
                        <span class="property-name">行号</span>
                        <span class="property-value">{{supplier.bankNumber}}</span>
                    </el-col>
                    <el-col :span="12" class="property-group">
                        <span class="property-name">银行账号</span>
                        <span class="property-value">{{supplier.accountNumber}}</span>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="24" class="property-group">
                        <span class="property-name">备注</span>
                        <span class="property-value">{{supplier.remark}}</span>
                    </el-col>
                </el-row>
            </el-card>

            <el-card header="其他附件" shadow="never" class="m-3">
                <multi-file-uploader v-model="supplier.otherAttachments" readonly />
                <el-empty v-if="supplier.otherAttachments.length==0" />
            </el-card>
        </div>
        <div v-else>
            <el-card shadow="never" class="m-3">
                <el-empty description="您还未注册供应商" />
            </el-card>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                supplier: {},
            };
        },
        methods: {
            async loadSupplier() {
                let response = await this.$axios.get('/api/Supplier/GetSupplier', { params: { id: this.$store.state.user.id } });
                this.supplier = response.data;
            },
        },
        created() {
            this.loadSupplier();
        },
    };
</script>

<style>
    .property-group {
        line-height: 40px;
    }

    .property-name {
        display: inline-block;
        color: gray;
    }

    .property-name::after {
        content: '：';
    }

    .property-value {
        display: inline-block;
    }
</style>